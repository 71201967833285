import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NominationService {

    public constructor(
        private http: HttpClient) {
    }

    public getWall(offset: number = 0, limit: number = 15): Observable<Object> {
        const params = new HttpParams({
            fromObject: {
              limit: limit.toString(),
              offset: offset.toString()
            }
        });
        const httpOptions = {
            params
        };
        return this.http.get<Object>('/nominations/wall', httpOptions);
    }
}
