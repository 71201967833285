import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { paths } from '../../routing-paths';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.authenticationService.logout();
        this.toastr.success(this.translate.instant('auth.message.loggedOutSuccessfully'),
                            this.translate.instant('auth.message.logoutSuccess'));
        this.router.navigate([paths.LOGIN]);
    }
}
