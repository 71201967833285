<div class="login-page">

    <div>
        <form [formGroup]="loginModeForm">
            <div class="btn-group btn-group-toggle my-3 w-100 login-mode-selector" data-toggle="buttons">
                <label class="btn btn-secondary active" translate>
                    <input type="radio" value="email" formControlName="loginMode" autocomplete="off"
                        checked>loginPage.loginModes.email
                </label>
                <label class="btn btn-secondary" translate>
                    <input type="radio" value="pairingCode" formControlName="loginMode"
                        autocomplete="off">loginPage.loginModes.pairingCode
                </label>
            </div>
        </form>

        <div class="login-card d-flex flex-column justify-content-center align-items-center"
            *ngIf="loginModeForm.controls.loginMode?.value == 'pairingCode'">
            <h3 translate>loginPage.caption</h3>
            <h2>{{display.code}}</h2>
        </div>
        <div class="login-card" *ngIf="loginModeForm.controls.loginMode?.value == 'email'">
            <form [formGroup]="emailLoginForm">
                <div class="form-group">
                    <input type="email" class="form-control" formControlName="email" autocomplete="off"
                      [placeholder]="'loginPage.email' | translate"
                        (keyup.enter)="loginEmail()">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" formControlName="password" autocomplete="off"
                        [placeholder]="'loginPage.password' | translate"
                        (keyup.enter)="loginEmail()">
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn btn-primary" type="button" (click)="loginEmail()"
                        translate>loginPage.button.loginEmail</button>
                </div>
            </form>
        </div>
    </div>
    <span id="version" [title]="version + ' #' + buildNumber + ' (' + commitId + ')'">{{version}}</span>
</div>

