import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SpinnerChanges, SpinnerService } from 'src/app/services/spinner.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private spinnerCount = 0;
    private spinnerSubscription: Subscription;

    constructor(
        private spinnerService: SpinnerService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService
    ) {
        this.translate.setDefaultLang(environment.defaultLanguage);
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use(environment.language);
    }

    ngOnInit() {
        this.setupSpinner();
    }

    ngOnDestroy() {
        this.spinnerSubscription.unsubscribe();
    }

    setupSpinner() {
        this.spinnerSubscription = this.spinnerService.spinner.subscribe((spinnerChange: SpinnerChanges) => {
            switch (spinnerChange) {
                case SpinnerChanges.START:
                    this.spinnerCount++;
                    break;
                case SpinnerChanges.FINISH:
                    if (this.spinnerCount > 0) {
                        this.spinnerCount--;
                    }
                    break;
                case null:
                    this.spinnerCount = 0;
                    break;
            }

            this.spinnerCount
                ? this.spinner.show()
                : this.spinner.hide();

        });
    }

}
