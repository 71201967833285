import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
    pure: true
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
        if (value.length <= limit) {
            return value;
        }

        if (completeWords) {
            limit = value.substr(0, limit).lastIndexOf(' ');
        }

        return value.length > limit
            ? `${value.substr(0, limit)}${ellipsis}`
            : value;
    }
}
