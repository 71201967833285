import { Directive, ElementRef, QueryList, ViewChildren } from '@angular/core';

@Directive({
  selector: '.current-card'
})
export class CurrentCardDirective {

  constructor() { }

}
