import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  constructor(private http: HttpClient) { }

  public register(): Observable<Object> {
    return this.http.post<Object>(`/displays/register`, {});
  }

  public getStatus(displayIdentifier: string): Observable<Object> {
    return this.http.get<Object>(`/displays/${displayIdentifier}/status`);
  }
}
