import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Display } from 'src/app/models/display';
import { paths } from 'src/app/routing-paths';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DisplayService } from 'src/app/services/display.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { version, buildNumber, commitId }  from '../../../../package.json';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    display: Display = new Display();
    private statusInterval: NodeJS.Timeout;
    private readonly STATUS_CHECK_INTERVAL = 3_000;

    loginModeForm: FormGroup = new FormGroup({
        'loginMode': new FormControl('email')
    });
    emailLoginForm: FormGroup = new FormGroup({
        'email': new FormControl(''),
        'password': new FormControl(''),
    });
    loginModeFormSubs: Subscription;
    pairCodeSubs: Subscription;
    version = version;
    buildNumber = buildNumber;
    commitId = commitId;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private displayService: DisplayService,
        private authenticationService: AuthenticationService,
        private spinnerService: SpinnerService) {
    }

    ngOnInit() {
        this.loginModeFormSubs = this.loginModeForm.valueChanges.subscribe(newFormValues => {
            if (newFormValues.loginMode == 'pairingCode' && !this.display?.identifier) {
                this.loadParingCode();
            }
        });
    }

    ngOnDestroy() {
        if (this.statusInterval) {
            clearInterval(this.statusInterval);
        }
        this.loginModeFormSubs?.unsubscribe();
        this.pairCodeSubs?.unsubscribe();
    }

    loginEmail() {
        let emailValue = this.emailLoginForm.controls['email'].value;
        let passwordValue = this.emailLoginForm.controls['password'].value;
        if (!emailValue) {
            this.toastr.error(this.translate.instant('loginPage.message.missingEmail'));
            return;
        }
        if (!passwordValue) {
            this.toastr.error(this.translate.instant('loginPage.message.missingPassword'));
            return;
        }
        this.spinnerService.showSpinner();
        this.authenticationService.loginFirebaseEmail(emailValue, passwordValue).then(result => {
            if (result?.user) {
                this.toastr.success(this.translate.instant('auth.message.loggedSuccessfully'),
                    this.translate.instant('auth.message.loginSuccess'));
                this.router.navigate([paths.DASHBOARD]);
            }
            else {
                this.toastr.error(this.translate.instant('loginPage.message.unsuccessfulLogin'));
            }
        }, reject => {
            if (reject?.message) {
                this.toastr.error(reject?.message);
            }
            else {
                this.toastr.error(this.translate.instant('loginPage.message.unsuccessfulLogin'));
            }
        }).finally(() => this.spinnerService.hideSpinner());
    }

    private loadParingCode() {
        this.pairCodeSubs = this.displayService.register().subscribe((displaydata: Display) => {
            this.display = displaydata;
            this.statusInterval = setInterval(() => {
                this.displayService.getStatus(this.display.identifier).subscribe((statusData: any) => {
                    if (statusData.displayStatus === 'ENABLED') {
                        clearInterval(this.statusInterval);
                        this.authenticationService.loginDisplayIdentifier(this.display.identifier, this.display.code);
                        this.toastr.success(this.translate.instant('auth.message.loggedSuccessfully'),
                            this.translate.instant('auth.message.loginSuccess'));
                        this.router.navigate([paths.DASHBOARD]);
                    }
                });
            }, this.STATUS_CHECK_INTERVAL);
        });
    }
}
