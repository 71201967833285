import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.authenticationService.currenFirebaseUserIdToken).pipe(
            switchMap(firebaseIdToken => {
                if (firebaseIdToken) {
                    request = request.clone({
                        setHeaders:
                        {
                            'Authorization': `Bearer ${firebaseIdToken}`
                        }
                    });
                }
                else { //try display identifier
                    let currentAuth = this.authenticationService.currentDisplayAuthValue;
                    if (currentAuth && currentAuth.identifier) {
                        request = request.clone({
                            setHeaders: {
                                'X-Display-Identifier': currentAuth.identifier
                            }
                        });
                    }
                }
                return next.handle(request);
            })
        )
    }
}
