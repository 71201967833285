import { Directive, ElementRef, QueryList, ViewChildren } from '@angular/core';

@Directive({
  selector: '.carousel-item'
})
export class CarouselItemElement {

  constructor() { }

}
