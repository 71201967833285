<div class="dashboard-container" #container>
    <section class="top">
        <div class="current-card">
            <div class="top-left" (click)="next()" *ngIf="currentNomination"
                [ngClass]="{'picWithGif': currentNomination.gifUrl}">
                <div *ngIf="currentNomination.gifUrl" class="gif-container">
                    <div class="left-place-holder"></div>
                    <div class="gif-image-container">
                        <img class="gif-image" src="{{currentNomination.gifUrl}}">
                    </div>
                    <img class="profile-image-in-gif"
                        [src]="currentNomination.avatarUrl" (error)="currentNomination.avatarUrl = DEFAULT_AVATAR" />
                </div>
                <div class="nominated-pic" *ngIf="!currentNomination.gifUrl">
                    <img class="pic-holder" [src]="currentNomination.avatarUrl" (error)="currentNomination.avatarUrl = DEFAULT_AVATAR" />
                </div>
            </div>
            <div class="top-right" *ngIf="currentNomination">
                <div class="nominated-container">
                    <h3 class="recent-nominated">{{currentNomination.nominatedUser.name}}</h3>
                    <div class="card-points">
                        <img src="assets/images/favorite_border-24p.svg" class="heart-svg" />
                        <p>{{currentNomination.score}}</p>
                    </div>
                </div>
                <div>
                    <h3 class="recent-nominator">Nominated by: {{currentNomination.creatorUser.name}} -
                        {{ currentNomination.createdAt | timeago}}</h3>
                </div>
                <div class="emphasized-comment">
                    <p><em>„{{currentNomination.description | truncate : EMPHASIZED_COMMENT_MAX_LENGTH : true }}”</em>
                    </p>
                </div>
                <div *ngIf="currentNomination.moderatorComment" class="moderator-comment">
                    <p>
                        <em>{{ currentNomination.moderatorComment | truncate : MODERATOR_COMMENT_MAX_LENGTH : true }}</em>
                    </p>
                </div>
                <div class="tag-wrapper">
                    <div class="tags">
                        <p class="text-lowercase-capitalized-first-letter">
                            {{ currentNomination.category.categoryType }}
                        </p>
                    </div>
                    <div class="tags">
                        <p>{{ currentNomination.category.name }}</p>
                    </div>
                </div>
                <!--<img class="skull" src="assets/images/Path%2052.svg" />-->
            </div>
        </div>
    </section>

    <section class="bottom">
        <div class="cards carousel-item" [ngClass]="getVisibility(item)" *ngFor="let item of items;">
            <div class="card-top">
                <div class="card-pic"
                    [ngStyle]="{ 'background-image': 'url(' + item.avatarUrl + ')'}"></div>
                <div class="card-data">
                    <div class="card-name">{{item.nominatedUser.name}}</div>
                    <div class="card-points">
                        <img src="assets/images/favorite_border-24p.svg" class="heart-svg" />
                        <p>{{item.score}}</p>
                    </div>
                </div>
            </div>
            <div class="card-bottom block-with-text">
                <p><em>„{{item.description}}”</em></p>
            </div>
        </div>
    </section>
</div>
<div class="logout-button p-3" *ngIf="isEmailLogin">
    <button class="btn btn-secondary" (click)="logout()">Logout</button>
</div>
