import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

export enum SpinnerChanges { START = "START", FINISH = "FINISH" }

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinner: Observable<SpinnerChanges>
  private _subscribers: Subscriber<SpinnerChanges>[] = [];

  constructor() {
    this.spinner = new Observable(subscriber => {
      this._subscribers.push(subscriber);
    });
  }

  showSpinner() {
    this._subscribers.forEach(subscriber => subscriber.next(SpinnerChanges.START));
  }

  hideSpinner() {
    this._subscribers.forEach(subscriber => subscriber.next(SpinnerChanges.FINISH));
  }
}
