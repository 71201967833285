import { animate, AnimationBuilder, style } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_AVATAR } from '../consts/avatar.consts';
import { CarouselItemElement } from '../directives/carousel-item-element.directive';
import { CurrentCardDirective } from '../directives/current-card.directive';
import { paths } from '../routing-paths';
import { AuthenticationService } from '../services/authentication.service';
import { NominationService } from '../services/nomination.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChildren(CarouselItemElement, { read: ElementRef }) private itemsElements: QueryList<ElementRef>;
    @ViewChild(CurrentCardDirective, { read: ElementRef }) private currentCardElement: ElementRef;
    @ViewChild('container', { read: ElementRef }) private containerElement: ElementRef;

    readonly EMPHASIZED_COMMENT_MAX_LENGTH = 384;
    readonly MODERATOR_COMMENT_MAX_LENGTH = 160;

    private timing = '2000ms ease-in-out';
    private shortTiming = '1000ms ease-in-out';

    private actualCardIndex = 0;
    private prevCardIndex = -1;
    private beforePrevCardIndex = -1;

    private carouselIntervalId: NodeJS.Timeout;
    private loadAgainIntervalId: NodeJS.Timeout;

    private DISTANCE = 52;
    private CARD_LIMIT = 30;
    private CARD_CHANGE_INTERVAL = 15_000;
    private RELOAD_INTERVAL = this.CARD_CHANGE_INTERVAL * this.CARD_LIMIT;

    items = [];
    currentNomination: any = null;
    isEmailLogin: boolean = false;
    DEFAULT_AVATAR = DEFAULT_AVATAR;

    constructor(private builder: AnimationBuilder,
        private nominationService: NominationService,
        private spinnerService: SpinnerService,
        private authService: AuthenticationService,
        private router: Router) {
    }

    ngOnInit() {
        this.loadNominations();
        this.setIsEmailLogin();
    }

    next() {
        this.moveCarousel();
        this.changeCurrentCard();
    }

    logout() {
        this.authService.logout()
            .then(() => this.router.navigate([paths.LOGIN]));
    }

    private setIsEmailLogin() {
        this.authService.currentFirebaseAuthUser.then(user => {
            this.isEmailLogin = !!user;
        })
    }

    private moveCarousel() {
        if (this.items.length <= 1) {
            return;
        }

        this.beforePrevCardIndex = this.prevCardIndex;
        this.prevCardIndex = this.actualCardIndex;

        this.actualCardIndex++;

        if (this.actualCardIndex == this.items.length) {
            this.actualCardIndex = 0;
        }

        const elements = this.itemsElements.toArray();
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];

            let offset = 0;
            if (i >= this.actualCardIndex - 1) {
                offset = -this.actualCardIndex;
            } else {
                offset = (elements.length - this.actualCardIndex);
            }

            if (this.actualCardIndex == 0 && i == elements.length - 1) {
                offset = - elements.length;
            }

            const slideAnimation = this.builder.build([
                animate(this.timing, style({ transform: `translateX(${(offset - 1) * this.DISTANCE}vh)` }))
            ]);
            slideAnimation.create(element.nativeElement).play();
        }
    }

    private changeCurrentCard(): void {
        const fadeOutAnimationPlayer = this.builder.build([
            animate(this.shortTiming, style({ opacity: 0 }))
        ]).create(this.currentCardElement.nativeElement);
        fadeOutAnimationPlayer.onDone(() => {
            this.currentNomination = this.items[this.actualCardIndex];
            this.builder.build([  // fade in animation
                animate(this.shortTiming, style({ opacity: 1 }))
            ]).create(this.currentCardElement.nativeElement).play();
        });
        fadeOutAnimationPlayer.play();
    }

    getVisibility(item: any): string {
        if (this.items.length <= 1) {
            return 'visible';
        } else if (item == this.items[this.beforePrevCardIndex]) {
            return 'fade-in';
        } else if (item == this.items[this.prevCardIndex]) {
            return 'hidden';
        } else if (item == this.items[this.actualCardIndex]) {
            return 'fade-out';
        } else {
            return 'visible';
        }
    }

    ngAfterViewInit() {
        this.setUpAutoCarousel();
        this.setUpLoadAgain();
    }

    private loadNominations(): void {
        this.spinnerService.showSpinner();
        this.nominationService.getWall(0, this.CARD_LIMIT).subscribe((result: any) => {
            const results: any[] = result.data;
            results.forEach(element => {
                element.createdAt = new Date(element.createdAt as string);
                element.avatarUrl = this.getUserImage(element.nominatedUser?.email, true);
            });

            const fadeOutAnimation = this.builder.build([
                animate(this.shortTiming, style({ opacity: 0 }))
            ]).create(this.containerElement.nativeElement);
            fadeOutAnimation.onDone(() => {
                this.actualCardIndex = 0;
                this.prevCardIndex = -1;
                this.beforePrevCardIndex = -1;

                this.items = results;
                this.currentNomination = this.items[0];

                this.builder.build([
                    animate(this.shortTiming, style({ opacity: 1 }))
                ]).create(this.containerElement.nativeElement).play();
            });
            fadeOutAnimation.play();

            this.spinnerService.hideSpinner();
        }, error => {
            console.log('request error', error);
        });
    }

    private setUpAutoCarousel() {
        this.carouselIntervalId = setInterval(() => {
            this.next();
        }, this.CARD_CHANGE_INTERVAL);
    }

    private setUpLoadAgain() {
        this.loadAgainIntervalId = setInterval(() => {
            location.reload();
        }, this.RELOAD_INTERVAL);
    }
    
    private getUserImage(email: string, inBig: boolean = false): string {
        if (!email) {
            return DEFAULT_AVATAR;
        }
        const uname = email.split('@')[0].toLowerCase();
        return `https://static.brighthills.com/images/employees/${uname}@brighthills.com-${inBig ? '800x800' : '150x150'}.png`;
    }    
    
    ngOnDestroy() {
        if (this.carouselIntervalId) {
            clearInterval(this.carouselIntervalId);
        }
        if (this.loadAgainIntervalId) {
            clearInterval(this.loadAgainIntervalId);
        }
    }
}
