import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Auth } from '../models/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private currentAuthSubject: BehaviorSubject<Auth>;
    public currentAuth: Observable<Auth>;

    constructor(private fireAuth: AngularFireAuth) {
        this.currentAuthSubject = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('currentAuth')));
        this.currentAuth = this.currentAuthSubject.asObservable();
    }

    public get currentDisplayAuthValue(): Auth {
        return this.currentAuthSubject.value;
    }

    public get currentFirebaseAuthUser(): Promise<any> {
        return this.fireAuth.authState.pipe(take(1)).toPromise();
    }

    public get currenFirebaseUserIdToken(): Promise<any> {
        return this.currentFirebaseAuthUser.then(user => {
            if (user) {
                console.log('user', user);
                let fUser = user as firebase.default.User;
                return fUser.getIdToken(true);
            }
            else {
                return Promise.resolve(null);
            }
        });
    }

    loginDisplayIdentifier(identifier: string, code: string) {
        const auth = {
            identifier,
            code,
        };
        localStorage.setItem('currentAuth', JSON.stringify(auth));
        this.currentAuthSubject.next(auth);
    }

    loginFirebaseEmail(email: string, password: string): Promise<any> {
        return this.fireAuth.signInWithEmailAndPassword(email, password);
    }

    logout(): Promise<any> {
        localStorage.removeItem('currentAuth');
        return this.fireAuth.signOut().then(() => {
            this.currentAuthSubject.next(null);
        });
    }
}
