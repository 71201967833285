import { Routes } from "@angular/router";
import { LoginComponent } from './components/login/login.component';

import { AuthGuard } from './guards/auth.guard';
import { LogoutComponent } from './components/logout/logout.component';
import { paths } from './routing-paths';
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
    { path: paths.ROOT, redirectTo: `/${paths.DASHBOARD}`, pathMatch: 'full' },
    { path: paths.LOGIN, component: LoginComponent },
    { path: paths.LOGOUT, component: LogoutComponent },
    { path: paths.DASHBOARD, component: DashboardComponent, canActivate: [AuthGuard] }
];


